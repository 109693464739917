<template>
  <b-modal
    id="print-history-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">Imprimir histórico</div>
      <span class="icon-box">
        <v-close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row class="mb-3" v-if="view === 'generate'">
        <b-col cols="5">
          <b-form-group>
            <label class="d-flex justify-content-between title-styles">
              Período de
              <span
                class="checkbox"
              >
                <input
                  type="checkbox"
                  v-model="all"
                />
                Todo período 
              </span>
            </label>
            <date-picker
              v-model="form.start_date"
              name="start_date"
              format="MM/YYYY"
              placeholder="MM/AAAA"
              type="month"
              :lang="langDatePicker"
              :disabled-date="disabledBeforeMedicalRecord"
              :clearable="false"
              :disabled="all"
              class="full"
            />
            <v-close
              class="close-icon"
              v-show="form.start_date"
              @click="() => {
                form.start_date = null
                form.end_date = null  
                all = false
              }"
            />
            <div v-if="validated && !form.start_date && !all" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="5">
          <b-form-group>
            <label class="title-styles">Período até</label>
            <date-picker
              v-model="form.end_date"
              name="rule_from"
              format="MM/YYYY"
              type="month"
              placeholder="MM/AAAA"
              :lang="langDatePicker"
              :clearable="false"
              class="full"
              :disabled="!form.start_date || all"
              :disabled-date="disabledBeforeStartDate"
            />
            <v-close
              class="close-icon"
              v-show="form.end_date"
              @click="() => {
                form.end_date = null
                all = false
              }"
            />
            <div v-if="validated && !form.start_date && !all" class="custom-invalid-feedback">
              Campo obrigatório
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="1">
          <b-button
            variant="primary"
            class="mt-button"
            size="lg"
            :disabled="!form.start_date || !form.end_date"
            @click="generatePatientHistory"
          >
            Gerar
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mb-3" v-else>
         <b-col cols="12" class="mb-5 mt-4">
          <div class="d-flex justify-content-center">
            <b-spinner class="loading" variant="primary" />
          </div>
          <div class="d-flex justify-content-center loading-label">
            Carregando ...
          </div>
        </b-col>

        <b-col cols="12" class="message">
          Olá, recebemos sua solicitação, o histórico será carregado e <b> em breve estará disponível </b> para download, <b> você pode utilizar o Eyecare BI normalmente </b> ou desligar o computador enquanto o histórico é carregado.
          <br /><br />
          <b> Avisaremos quando estiver pronto </b> pelo menu superior. O documento ficará registrado no prontuário do paciente no <b> histórico de arquivos </b>!
        </b-col>

        <b-col cols="12"  class="d-flex justify-content-center">
          <b-button
            variant="primary"
            class="mt-4"
            size="lg"
            @click="closeModal"
          >
            Concluir
          </b-button>
        </b-col>

      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import Close from '@/assets/icons/close.svg'

export default {
  name: 'ProfileModal',
  components: {
    'v-close': Close,
  },
  props: {
    patientId: String,
    firstMedicalRecord: Object,
    lastMedicalRecord: Object
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),

      form: {
        start_date: null,
        end_date: null,
      },
      all: false,

      view: 'generate',
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      validated: false
    }
  },
  methods: {
    isValidForm() {
      this.validated = true
      if(this.all) return true
      else{
        if(this.moment(this.form.end_date) < this.moment(this.form.start_date)){
          this.$toast.warning("O campo 'Peródo até' deve ter uma data maior que a do campo 'Período de' !")
          return false
        }
        else return true
      }
    },
    generatePatientHistory() {
      if (!this.isValidForm()) return
      this.api.createPatientHistory({
        professional_id: this.user.id,
        clinic_id: this.clinic.id,
        patient_id: this.patientId,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        tabId: window.sessionStorage.getItem('tabId')
      })
      .then(() => {
        this.view = 'awaiting'
      })
      .catch((error) => {
        this.$toast.error(error.message)
      })
    },
    closeModal() {
      this.validated = false
      this.view = 'generate'
      this.form.start_date = null
      this.form.end_date = null
      this.all = false
      this.$bvModal.hide('print-history-modal')
    },
    disabledBeforeMedicalRecord(date) {
      return date < new Date(this.moment(this.firstMedicalRecord.start_datetime).subtract(1, 'month'))
        || date > new Date(this.moment(this.lastMedicalRecord.start_datetime))
    },
    disabledBeforeStartDate(date) {
      return date < new Date(this.form.start_date)
        || date > new Date(this.moment(this.lastMedicalRecord.start_datetime))
    }
  },
  watch: {
    all: function(newVal) {
      if (newVal) {
        this.form.start_date = this.moment(this.firstMedicalRecord.start_datetime)
          .startOf('month')
          .toDate()
        this.form.end_date = this.moment(this.lastMedicalRecord.start_datetime)
          .endOf('month')
          .subtract(1, 'day')
          .toDate()
      } else {
        this.form.start_date = null
        this.form.end_date = null
      }
    }
  }
}
</script>

<style lang="scss">
#print-history-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .title-styles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin: 0;
  }

  .checkbox {
      color: gray;
      font-size: 15px;
      text-align: right;
  }

  .loading-label{
    font-weight: 700;
    font-size: 24px;
    line-height: 132%;
    color: var(--dark-blue);
  }

  .message{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--type-active);
  }

  .mt-button{
    margin-top: 30px;
  }

  .close-icon{
    position: absolute;
    left: 82%;
    width: 36px;
    height: 36px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;

    svg {
      fill: var(--neutral-500);
    }
  }
}
</style>